.onlineChat-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .tab-bar {
    width: 70px;
    color: #fff;
    background: #0C6AFF;
    display: flex;
    flex-direction: column;
    .user-box {
      height: 80px;
      display: flex;
      .user-avatar {
        display: flex;
        margin: auto;
        position: relative;
        .user-img {
          width: 42px;
          height: 42px;
          border: 2px solid #fff;
          border-radius: 50%;
          box-sizing: border-box;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            max-width: 100%;
          }
        }
        .bg {
          width: 12px;
          height: 12px;
          background: #fff;
          display: block;
          position: absolute;
          right: -2px;
          bottom: 4px;
          border-radius: 50%;
        }
        .icon-status {
          cursor: pointer;
          position: absolute;
          right: -3px;
          bottom: 3px;
          display: block;
          font-size: 14px;
          line-height: 1;
          border-radius: 50%;
        }
        .status-dialog {
          width: 82px;
          border-radius: 4px;
          position: absolute;
          top: 26px;
          left: 48px;
          background: rgba(240, 239, 242, .95);
          border: 1px solid #DCDCDC;
          padding: 4px 0;
          .status-item {
            height: 22px;
            padding: 0 20px 0 10px;
            color: #666;
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            &:last-child {
              margin-bottom: 0;
            }
            .text {
              margin-left: 8px;
            }
            &.current {
              pointer-events: none;
            }
          }
        }
      }
    }
    .menu {
      flex: 1;
      height: 1%;
      .menu-item {
        width: 100%;
        height: 70px;
        line-height: 1;
        cursor: pointer;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        transition: all .3s;
        .iconfont {
          font-size: 22px;
        }
        .text {
          font-size: 16px;
          margin-top: 10px;
        }
        &.current {
          background: #0B64F0;
        }
      }
    }
    .set-box {
      height: 100px;
      position: relative;
      .set-btn {
        cursor: pointer;
        padding: 0 0 20px;
        text-align: center;
        .iconfont {
          display: block;
          font-size: 22px;
        }
      }
      .set-dialog {
        z-index: 9;
        position: absolute;
        left: 50px;
        top: -50px;
        width: 100px;
        padding: 10px 0;
        border-radius: 8px;
        border: 1px solid #DCDCDC;
        background: rgba(239, 238, 241, 0.95);
        .set-item {
          line-height: 32px;
          padding: 0 12px;
          color: #333;
          cursor: pointer;
          &:hover {
            color: #fff;
            background: #3B88F6;
          }
        }
      }
    }
  }
  .contact-bar {
    width: 300px;
    display: flex;
    flex-direction: column;
    .user-info {
      height: 80px;
      padding: 10px;
      background: #1979FF;
      box-sizing: border-box;
      .user-name {
        color: #fff;
        font-size: 16px;
        line-height: 1;
        margin: 10px 0;
        font-weight: bold;
      }
    }
    .user-container {
      height: 1%;
      flex: 1;
      display: flex;
      flex-direction: column;
      border-right: 1px solid #eaeaea;
      .user-tab {
        display: flex;
        .tab-item {
          width: 50%;
          line-height: 39px;
          text-align: center;
          border-bottom: 1px solid #E5E5E5;
          position: relative;
          cursor: pointer;
          &:after {
            content: '';
            width: 1px;
            height: 14px;
            background: #EEEEEE;
            position: absolute;
            top: 13px;
            right: 0;
          }
          .iconfont {
            color: #BFBFBF;
            font-size: 20px;
            display: block;
            transition: all .3s;
            &:hover {
              color: #0C6AFF;
            }
          }
          .full {
            display: none;
          }
          &.current {
            .empty {
              display: none;
            }
            .full {
              color: #0C6AFF;
              display: block;
            }
          }
          &:last-child {
            &:after {
              content: none;
            }
          }
        }
      }
      .user-content {
        height: 1%;
        flex: 1;
        ::v-deep .el-scrollbar__wrap {
          overflow-x: hidden;
        }
        .levelOne-item {
          display: flex;
          cursor: pointer;
          padding: 0 15px;
          line-height: 40px;
          .iconfont {
            color: #BFBFBF;
          }
          .text {
            width: 1%;
            flex: 1;
            font-weight: bold;
            margin-left: 6px;
          }
        }
        .levelTwo-item {
          height: 60px;
          position: relative;
          display: flex;
          align-items: center;
          padding: 0 20px 0 25px;
          transition: all .3s;
          cursor: pointer;
          border-bottom: 1px solid #eee;
          .user-avatar {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            img {
              max-width: 100%;
              max-height: 100%;
              margin: auto;
            }
            .avatar {
              filter: grayscale(100%);
              &.current {
                filter: grayscale(0);
              }
            }
          }
          .content {
            width: 1%;
            flex: 1;
            color: #666;
            font-size: 12px;
            margin-left: 10px;
            .top {
              display: flex;
              align-items: center;
              .name {
                color: #333;
                font-size: 14px;
                width: 1%;
                flex: 1;
              }
              .waitTime {
                margin-left: 10px;
              }
              .iconfont {
                margin-left: 10px;
              }
              .collect {
                cursor: pointer;
              }
            }
            .bottom {
              display: flex;
              margin-top: 8px;
              .message {
                flex: 1;
                width: 1%;
                /*font-weight: 500;*/
              }
              .time {
                margin-left: 12px;
              }
            }
          }
          .close {
            position: absolute;
            top: 5px;
            right: 6px;
            color: #D2D2D2;
            font-size: 14px;
            line-height: 1;
            cursor: pointer;
            display: none;
            transition: all .3s;
          }
          &:hover {
            background: #E8F1FF;
            .close {
              display: block;
            }
          }
          &.current {
            background: #C1DFFF;
          }
        }
      }
    }
  }
  .main-container {
    width: 1%;
    flex: 1;
    display: flex;
    flex-direction: column;
    .main-top {
      height: 80px;
      border-bottom: 1px solid #eaeaea;
      box-sizing: border-box;
      padding: 20px 0 0 20px;
      display: flex;
      align-items: center;
      .main-top-left {
        display: flex;
        width: 1%;
        flex: 1;
        & > .user-avatar {
          width: 40px;
          height: 40px;
          display: flex;
          overflow: hidden;
          border-radius: 50%;
          img {
            max-width: 100%;
            max-height: 100%;
            margin: auto;
            filter: grayscale(100%);
            &.current {
              filter: grayscale(0);
            }
          }
        }
        & > .user-info {
          width: 1%;
          flex: 1;
          margin-left: 20px;
          /*line-height: 1;*/
          .user-name {
            font-size: 16px;
            font-weight: bold;
          }
          .train-name {
            color: #666;
            margin-top: 4px;
          }
        }
      }
    }
    .chat-container {
      flex: 1;
      height: 1%;
      background: #F0F1F1;
      & > ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
        & > .el-scrollbar__view {
          min-width: 500px;
        }
      }
      .scene-box {
        padding: 12px 20px;
        background: #fff;
        display: flex;
        align-items: center;
        .scene-text {
          width: 1%;
          flex: 1;
        }
        .link-blue-shadow {
          margin-left: 40px;
        }
      }
      .chat-content {
        padding: 12px 20px 0;
        .chat-item {
          display: flex;
          margin-bottom: 24px;
          .chat-user {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
            margin-top: 12px;
            img {
              max-width: 100%;
              filter: grayscale(100%);
              &.current {
                filter: grayscale(0);
              }
            }
          }
          .chat-message {
            width: 1%;
            flex: 1;
            margin-left: 14px;
            .line {
              color: #999;
              line-height: 1;
              display: flex;
              .text-overflow {
                max-width: 50%;
              }
            }
            .message-wrapper {
              display: flex;
              align-items: flex-end;
            }
            .message-box {
              margin-top: 6px;
              background: #fff;
              padding: 10px 10px;
              border-radius: 4px;
              display: inline-block;
              position: relative;
              line-height: 20px;
              max-width: 50%;
              box-sizing: border-box;
              ::v-deep p {
                margin: 0;
              }
              ::v-deep img {
                vertical-align: bottom;
                max-width: 100%;
                max-height: 300px;
              }
              &:before {
                content: '';
                position: absolute;
                top: 14px;
                left: -6px;
                width: 0;
                height: 0;
                border-top: 6px solid transparent;
                border-bottom: 6px solid transparent;
                border-right: 6px solid #fff;
              }
            }
            .read-status {
              display: none;
              color: #0C6AFF;
              font-size: 12px;
              margin-left: 10px;
              &.current {
                color: #666;
              }
            }
          }
          &.current {
            flex-direction: row-reverse;
            text-align: right;
            //margin-bottom: 24px;
            //&:last-child {
            //  margin-bottom: 0;
            //}
            .chat-message {
              margin-left: 0;
              margin-right: 14px;
              text-align: right;
              .line {
                flex-direction: row-reverse;
              }
              .message-wrapper {
                flex-direction: row-reverse;
              }
              .message-box {
                background: #C7E4FF;
                text-align: left;
                &.white {
                  background: #fff;
                }
                &:before {
                  content: none;
                }
                &:after {
                  content: '';
                  position: absolute;
                  top: 14px;
                  right: -6px;
                  width: 0;
                  height: 0;
                  border-top: 6px solid transparent;
                  border-bottom: 6px solid transparent;
                  border-left: 6px solid #C7E4FF;
                }
                &.includeGood {
                  .goods-info {
                    display: flex;
                    align-items: center;
                    .good-cover {
                      width: 80px;
                      height: 80px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      overflow: hidden;
                      img {
                        max-width: 100%;
                      }
                    }
                    .good-right {
                      width: 1%;
                      flex: 1;
                      margin-left: 10px;
                      p {
                        line-height: 1;
                        margin-bottom: 8px;
                        &:last-child {
                          margin-bottom: 0;
                        }
                      }
                    }
                    &.check {
                      margin-top: 12px;
                      .good-cover {
                        width: 60px;
                        height: 60px;
                      }
                      .good-right {
                        height: 60px;
                        margin-left: 0;
                        background: #F9F9F9;
                        display: flex;
                        align-items: center;
                        p {
                          line-height: 1.5;
                          margin-left: 10px;
                        }
                      }
                    }
                  }
                  .money {
                    text-align: right;
                    line-height: 1;
                    padding: 12px 0;
                    border-bottom: 1px solid #E5E5E5;
                  }
                  .item-line {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 12px;
                    .line-left {
                      width: 70px;
                      text-align-last: justify;
                    }
                    .line-right {
                      width: 1%;
                      flex: 1;
                    }
                  }
                }
              }
              .read-status {
                display: block;
                margin-right: 10px;
                margin-left: 0;
              }
            }
          }
        }
      }
    }
    .send-message {
      position: relative;
      height: 220px;
      .default-emoji {
        height: 40px;
        background: #F2F5F5;
        display: flex;
        overflow: hidden;
        .emoji-item {
          height: 40px;
          padding: 0 4px;
          display: flex;
          cursor: pointer;
          img {
            width: 24px;
            margin: auto;
          }
        }
      }
      .emojiPack-wrapper {
        left: -177px;
        bottom: 200px;
      }
      .send-bottom {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;
        padding-right: 20px;
        .sendBtn {
          height: 32px;
          background: #fff;
          border-radius: 2px;
          cursor: pointer;
          border: 1px solid #DCDCDC;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          .text {
            position: relative;
            padding: 0 8px;
            line-height: 30px;
            transition: all .3s;
            &:after {
              content: '';
              position: absolute;
              top: 8px;
              right: 0;
              width: 1px;
              height: 14px;
              background: #BFBFBF;
            }
            &:hover {
              color: #0C6AFF;
            }
          }
          .el-icon-arrow-down {
            color: #A0A0A0;
            padding: 0 8px;
            line-height: 30px;
            &:hover {
              color: #0C6AFF;
            }
          }
        }
        .send-methods {
          position: absolute;
          top: -70px;
          right: 20px;
          background: #fff;
          border: 1px solid #DCDCDC;
          width: 112px;
          .method-item {
            cursor: pointer;
            padding: 12px;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
              margin-right: 8px;
              transition: all .3s;
            }
            i {
              display: none;
            }
            &:hover {
              span {
                color: #0C6AFF;
              }
            }
            &.current {
              i {
                display: block;
                color: #00DF69;
              }
            }
          }
        }
      }
    }
  }
  .phraseChat-container {
    width: 280px;
    display: flex;
    flex-direction: column;
    .phraseChat-header {
      height: 80px;
      border-bottom: 1px solid #eaeaea;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      .header-item {
        color: #BFBFBF;
        font-size: 24px;
        padding: 0 12px;
        cursor: pointer;
        transition: all .3s;
        &.current, &:hover {
          color: #0C6AFF;
        }
      }
    }
    .phraseChat-content {
      flex: 1;
      height: 1%;
      border-right: 1px solid #eaeaea;
      border-left: 1px solid #eaeaea;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      .history-tab {
        display: flex;
        border-bottom: 1px solid #eaeaea;
        .tab-item {
          width: 50%;
          color: #999;
          cursor: pointer;
          line-height: 40px;
          text-align: center;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 13px;
            width: 1px;
            height: 14px;
            background: #eaeaea;
          }
          &:first-child {
            &:before {
              content: none;
            }
          }
          &:hover {
            color: #0C6AFF;
          }
          &.current {
            color: #0C6AFF;
            &:after {
              content: '';
              position: absolute;
              left: 50%;
              bottom: 0;
              height: 2px;
              width: 28px;
              background: #0C6AFF;
              transform: translateX(-50%);
            }
          }
        }
      }
      .history-container {
        flex: 1;
        height: 1%;
        display: flex;
        flex-direction: column;
        .history-content {
          flex: 1;
          height: 1%;
          & > ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
          }
        }
        .search-box {
          width: auto;
          margin: 15px 10px;
        }
      }
      .historyImg-container {
        flex: 1;
        height: 1%;
        & > ::v-deep .el-scrollbar__wrap {
          overflow-x: hidden;
        }
      }
      .graphic-item {
        font-size: 12px;
        padding: 10px 10px 0;
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
        .user-info {
          color: #0C6AFF;
        }
        .reply-content {
          margin-top: 4px;
          ::v-deep {
            img {
              display: block;
              max-width: 150px;
              max-height: 150px;
            }
          }
        }
      }

      .phrase-header {
        color: #0C6AFF;
        line-height: 40px;
        text-align: center;
      }
      .search-box {
        width: auto;
        margin: 0 10px;
      }
      .phrase-content {
        flex: 1;
        height: 1%;
        margin-top: 15px;
        & > ::v-deep .el-scrollbar__wrap {
          overflow-x: hidden;
        }
        .phrase-item {
          position: relative;
          margin-bottom: 15px;
        }
        .levelOne {
          display: flex;
          align-items: center;
          margin: 0 20px 10px;
          cursor: pointer;
          .levelOne-icon {
            margin-right: 6px;
          }
          .levelOne-text {
            max-width: 178px;
          }
        }
        .levelTwo {
          height: 24px;
          padding: 0 20px 0 40px;
          display: flex;
          align-items: center;
          position: relative;
          transition: all .3s;
          .number {
            color: #0C6AFF;
            margin-right: 6px;
            font-weight: bold;
            line-height: 24px;
          }
          .hover-content {
            position: absolute;
            top: 24px;
            left: 80px;
            border: 1px solid #DCDCDC;
            background: rgba(239, 238, 241, 0.95);
            border-radius: 2px;
            display: none;
            font-size: 12px;
            padding: 2px 4px;
            z-index: 1;
            transition: all 1s;
            transform: scale(.95);
            ::v-deep img {
              max-width: 14px;
              max-height: 14px;
              margin-left: 6px;
              vertical-align: unset;
            }
            ::v-deep p {
              margin: 0;
            }
          }
          .content {
            flex: 1;
            width: 1%;
            line-height: 24px;
            ::v-deep img {
              max-width: 14px;
              max-height: 14px;
              margin-left: 6px;
              vertical-align: unset;
            }
            ::v-deep p {
              margin: 0;
            }
            &:hover + .hover-content {
              display: block;
            }
          }
          &:hover, &:focus {
            background: #DEEBFF;
          }
        }
      }
      .phrase-bottom {
        display: flex;
        margin: 10px 10px 20px;
        .left {
          flex: 1;
          width: 1%;
          .el-link + .el-link {
            margin-left: 20px;
          }
        }
      }
    }
  }
  .right-box {
    width: 360px;
    display: flex;
    flex-direction: column;
    .right-top {
      height: 80px;
      border-bottom: 1px solid #eaeaea;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 30px;
      .submit-btn {
        font-size: 18px;
        color: #666;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: all .3s;
        .iconfont {
          font-size: 24px;
          color: #1979FF;
          margin-right: 8px;
        }
        &:hover {
          color: #1979FF;
        }
      }
    }
    .right-container {
      flex: 1;
      height: 1%;
      display: flex;
      flex-direction: column;
      .right-tab {
        display: flex;
        .tab-item {
          color: #999;
          width: 106px;
          height: 40px;
          cursor: pointer;
          line-height: 40px;
          text-align: center;
          background: #F3F6F9;
          &.current {
            color: #333;
            background: #fff;
          }
        }
      }
      .right-header {
        display: flex;
        align-items: center;
        padding: 16px 10px 0;
        .el-button + .el-button {
          margin-left: 20px;
        }
      }
      .right-consult {
        border-bottom: 1px solid #E5E5E5;
        padding-left: 18px;
        .consult-item {
          display: inline-block;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #666;
          position: relative;
          margin-right: 40px;
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
          &.current {
            color: #0C6AFF;
            &:after {
              content: '';
              position: absolute;
              left: 0;
              right: 0;
              bottom: -1px;
              height: 2px;
              background: #0C6AFF;
            }
          }
        }
      }
      .right-content {
        flex: 1;
        height: 1%;
        padding: 10px 0 0;
        & > ::v-deep .el-scrollbar__wrap {
          overflow-x: hidden;
          .el-scrollbar__wrap {

          }
        }
      }
    }
  }
}
.gray {
  color: #b1bbc6;
}
.red {
  color: #F61F03;
}
.green {
  color: #00DF69;
}
.yellow {
  color: #FFB000;
}
.golden {
  color: #B67F00;
}
.search-box {
  //::v-deep .el-input__inner {
  //  height: 24px;
  //  line-height: 24px;
  //  font-size: 12px;
  //}
  //::v-deep .el-input__icon {
  //  line-height: 24px;
  //}
}
.no-data {
  flex: 1;
  height: 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .no-img {
    max-width: 200px;
  }
  .text {
    color: #999;
    margin-top: 12px;
    padding: 0 20px;
  }
}
.img-uploader {
  width: 78px;
  height: 78px;
  background: #F6F7F7;
  border: 1px dashed #E5E5E5;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 12px;
  position: relative;
  .quickTalk-img {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
  .add-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    line-height: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .add-icon {
      color: #7D7D7D;
      font-size: 24px;
    }
    .add-text {
      color: #7D7D7D;
      font-size: 12px;
      margin-top: 12px;
    }
  }
}
.phrase-form {
  .detail-item {
    ::v-deep .el-form-item__content {
      position: relative;
    }
    .tiny-bottom {
      position: absolute;
      left: 0;
      width: 100%;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      padding: 0 10px;
      color: #BFBFBF;
      box-sizing: border-box;
      .emoji-icon {
        cursor: pointer;
        padding-right: 10px;
      }
    }
  }
}
.input-gray {
  ::v-deep .el-input__inner {
    border-color: #F6F7F7;
    background-color: #F6F7F7;
  }
}
.select-gray {
  ::v-deep .el-input__inner {
    border-color: #F6F7F7;
    background-color: #F6F7F7;
  }
}
::v-deep .tox-tinymce {
  border-color: #E5E5E5;
}
.operate-wrapper {
  background: #EFEEF1;
  border: 1px solid #DCDCDC;
  padding: 10px 0;
  position: absolute;
  width: 100px;
  z-index: 9;
  box-sizing: border-box;
  border-radius: 8px;
  .operate-item {
    padding: 0 12px;
    line-height: 24px;
    cursor: pointer;
    &:hover {
      color: #fff;
      background: #3B88F6;
    }
  }
}
/*::v-deep .tox-tinymce {*/
/*    p {*/
/*        margin: 0;*/
/*    }*/
/*}*/
.emojiPack-wrapper {
  position: absolute;
  left: -226px;
  bottom: 40px;
  width: 480px;
  border: 1px solid #E5E5E5;
  .emojiPack-list {
    display: flex;
    flex-wrap: wrap;
    background: #fff;
    padding-bottom: 20px;
    .emojiPack-item {
      width: 40px;
      height: 40px;
      display: flex;
      cursor: pointer;
      img {
        margin: auto;
        width: 24px;
        height: 24px;
      }
    }
  }
  .emojiPack-bottom {
    height: 40px;
    background: #f4f4f4;
    display: flex;
    align-items: center;
    padding: 0 5px;
    .item {
      width: 44px;
      height: 30px;
      display: flex;
      background: #fff;
      border-radius: 6px;
      cursor: pointer;
      img {
        width: 24px;
        height: 24px;
        margin: auto;
      }
    }
  }
  &:before {
    content: '';
    position: absolute;
    left: 233px;
    bottom: -8px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #f4f4f4;
    z-index: 1;
  }
  &:after {
    content: '';
    position: absolute;
    left: 233px;
    bottom: -9px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #E5E5E5;
  }
}
#tiny_item2 + ::v-deep .tox {
  border: none;
  background: #000;
  .tox-editor-header {
    .tox-toolbar-overlord {
      display: flex;
      justify-content: space-between;
      .tox-toolbar {
        background: none;
        .tox-toolbar__group {
          border-right: none;
          padding: 0 10px;
          .tox-tbtn {
            width: 24px;
            height: 24px;
            margin-bottom: 0;
            svg {
              fill: #BFBFBF;
            }
            &:hover {
              background: #f6faff;
            }
          }
        }
      }
    }
  }
  .tox-tbtn--enabled, .tox-tbtn--enabled:hover {
    background: #f6faff;
  }
}
.icon-bell {
  animation: rockBell .5s linear infinite alternate;
}
@keyframes rockBell {
  0% {
    transform: rotate(-60deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(60deg);
  }
}
.upload-quickTalk-wrapper {
  ::v-deep .el-form-item__content {
    .clear-quickTalk-img {
      background: #fff;
      border-radius: 50%;
      position: absolute;
      top: 17px;
      left: 70px;
      z-index: 1;
      font-size: 18px;
      color: #aaa;
      cursor: pointer;
      transition: all .3s;
      &:hover {
        color: red;
      }
    }
  }
}
.connectWord-wrapper {
  position: fixed;
  left: 570px;
  top: calc(100vh - 150px);
  background: #fff;
  border: 1px solid #DCDCDC;
  font-size: 12px;
  border-radius: 2px;
  max-width: 300px;
  min-width: 100px;
  ::v-deep .el-scrollbar__view {
    max-height: 119px;
  }
  .connect-item {
    line-height: 24px;
    padding: 0 4px;
    cursor: pointer;
    ::v-deep img {
      vertical-align: bottom;
    }
    &.current {
      background: #CAE6FF;
    }
  }
}

.searchUser-input {
  ::v-deep {
    .el-input__inner {
      height: 24px;
      line-height: 24px;
      color: #fff;
      font-size: 12px;
      border-color: #0C6AFF;
      background-color: #0C6AFF;
    }
    .el-input__prefix {
      color: #00368B;
    }
    .el-input__icon {
      line-height: 24px;
      &.el-icon-search {
        cursor: pointer;
      }
    }
  }
}

