
p {
    margin: 0;
}
.customerOrder {
    /*height: 1%;*/
    /*flex: 1;*/
    height: 100%;
    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
}
.demo-ruleForm {
    margin-top: 20px;
    margin-right: 20px;
}
.input-number {
    ::v-deep .el-input__inner {
        text-align: left;
    }
}

.address-info {
    margin-top: 30px;
    border-bottom: 1px solid #E5E5E5;
    .address-top {
        height: 40px;
        background: #F5F8FA;
        line-height: 40px;
        padding-left: 20px;
    }
    .address-content {
        border-top: 1px solid #E5E5E5;
        padding: 12px;
        .line {
            display: flex;
            margin-bottom: 14px;
            &:last-child {
                margin-bottom: 0;
            }
            .name {
                width: 60px;
                text-align-last: justify;
            }
            .detail {
                width: 1%;
                flex: 1;
                span {
                    margin-left: 20px;
                }
            }
        }
    }
}
.no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .no-img {
        max-width: 200px;
    }
    .text {
        color: #999;
        margin-top: 12px;
        padding: 0 20px;
    }
}
