
.right-content {
    flex: 1;
    height: 1%;
    padding: 10px 0 0;
    & > ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
        .el-scrollbar__wrap {

        }
    }
    .dialogue-item {
        font-size: 12px;
        position: relative;
        margin-bottom: 10px;
        padding: 0 20px 20px 10px;
        &:after {
            content: '';
            position: absolute;
            left: 10px;
            right: 20px;
            bottom: 0;
            border-bottom: 1px solid #D2D2D2;
        }
        &:last-child {
            margin-bottom: 0;
            &:after {
                content: none;
            }
        }
        .question {

        }
        .answer-box {
            padding: 10px;
            margin-top: 10px;
            border: 1px solid #eee;
            .model-answer {
                padding-bottom: 8px;
                border-bottom: 1px solid #eee;
                .answer {
                    margin-top: 4px;
                    color: #666;
                }
            }
            .my-answer {
                padding-top: 8px;
                .title {
                    .iconfont {
                        font-size: 12px;
                        margin-right: 6px;
                    }
                }
                .answer {
                    color: #666;
                    margin-top: 4px;
                    padding-left: 18px;
                }
            }
        }
        .testing-centre {
            color: #666;
            padding: 10px;
            margin-top: 10px;
            background: #eee;
            .centre-item {
                display: flex;
                margin-top: 10px;
                &:first-child {
                    margin-top: 0;
                }
                .left {
                    width: 54px;
                    text-align: right;
                }
                .right {
                    flex: 1;
                    width: 1%;
                    margin-left: 5px;
                }
                .tag-list {
                    display: flex;
                    flex-wrap: wrap;
                    margin-left: 0;
                    .tag {
                        color: #0C6AFF;
                        background: #DEEBFF;
                        border-radius: 2px;
                        padding: 0 6px;
                        line-height: 20px;
                        margin-top: 5px;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}
.red {
    color: #F61F03;
}
.green {
    color: #00DF69;
}
.yellow {
    color: #FFB000;
}
