
p {
    margin: 0;
}
.customerOrder {
    /*height: 1%;*/
    /*flex: 1;*/
    height: 100%;
    display: flex;
    flex-direction: column;;
    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
}
.form-wrapper {
    height: 1%;
    flex: 1;
}
.bottom-wrapper {
    padding: 18px 0;
    text-align: center;
    border-top: 1px solid #E5E5E5;
}
.demo-ruleForm {
    margin-top: 20px;
    margin-right: 20px;
}
.label-top {
    ::v-deep .el-form-item__label {
        line-height: 1.2;
    }
}
.font-normal {
    .el-radio {
        color: #333;
        font-weight: normal;
    }
}
.no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .no-img {
        max-width: 200px;
    }
    .text {
        color: #999;
        margin-top: 12px;
        padding: 0 20px;
    }
}
.red {
    color: #F61F03;
}
.goods-wrapper {
    .goods-list {
        margin-top: 14px;
        height: 300px;
        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .el-radio-group {
            width: 100%;
        }
    }
    .good-item {
        display: flex;
        align-items: center;
        margin-right: 0;
        border: 1px solid #E5E5E5;
        padding: 10px;
        border-top: none;
        &:first-child {
            border-top: 1px solid #E5E5E5;
        }
        ::v-deep {
            .el-radio__label {
                width: 1%;
                flex: 1;
                display: flex;
                align-items: center;
                .good-image {
                    width: 80px;
                    height: 80px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    background: red;
                    img {
                        max-width: 100%;
                    }
                }
                .good-info {
                    width: 1%;
                    flex: 1;
                    margin-left: 10px;
                    color: #333;
                    .red {
                        margin-top: 18px;
                    }
                }
            }
        }
    }
}
.current-good-wrapper {
    margin-top: 20px;
    .top {
        padding-left: 22px;
        color: #666;
    }
    .current-good {
        margin-top: 15px;
        padding: 10px 15px;
        background: #F3F3F3;
        display: flex;
        align-items: center;
        .good-image {
            width: 80px;
            height: 80px;
            display: flex;
            img {
                margin: auto;
                max-width: 100%;
            }
        }
        .good-info {
            margin: 0 10px;
            width: 1%;
            flex: 1;
            .line {
                &:first-child {
                    margin-bottom: 10px;
                }
            }
        }
        .iconfont {
            color: #666;
            cursor: pointer;
        }
    }
}
.input-number {
    ::v-deep .el-input__inner {
        text-align: left;
    }
}
