
p {
    margin: 0;
}
.customerOrder {
    /*height: 1%;*/
    /*flex: 1;*/
    height: 100%;
    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
}
.robot-info {
    display: flex;
    align-items: center;
    height: 70px;
    padding-left: 15px;
    .robot-avatar {
        width: 40px;
        height: 40px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        img {
            max-width: 100%;
            filter: grayscale(100%);
            &.current {
                filter: grayscale(0);
            }
        }
    }
    .info-right {
        margin-left: 10px;
        width: 1%;
        flex: 1;
    }
}
.goods-info {
    display: flex;
    align-items: center;
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
    padding: 10px 15px;
    .good-img {
        width: 80px;
        height: 80px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
            max-width: 100%;
        }
    }
    .info-right {
        width: 1%;
        flex: 1;
        margin-left: 10px;
        font-size: 12px;
        .line {
            display: flex;
            margin-bottom: 8px;
            line-height: 16px;
            &:last-child {
                margin-bottom: 0;
            }
            p {
                width: 90%;
                line-height: 1.2;
            }
            .iconfont {
                cursor: pointer;
                width: 10%;
                color: #7D7D7D;
                text-align: right;
                line-height: 1;
            }
            span {
                margin-left: 6px;
                font-size: 14px;
            }
        }
    }
}
.red {
    color: #F61F03;
}
.golden {
    color: #B67F00;
}
.coupons-list {
    .coupon-item {
        display: flex;
        margin: 10px 18px 0;
        position: relative;
        .btn-blue-shadow {
            position: absolute;
            right: 10px;
            bottom: 10px;
        }
        .item-left {
            width: 112px;
            height: 88px;
            background: url("../../../assets/images/student/coupon-bg.png") no-repeat;
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            line-height: 1;
            .price-line {
                margin-bottom: 15px;
                span {
                    font-size: 24px;
                }
            }
        }
        .item-right {
            border: 1px solid #E5E5E5;
            border-left: none;
            padding-left: 10px;
            width: 1%;
            flex: 1;
            font-size: 12px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .line {
                .tags {
                    line-height: 22px;
                    padding: 0 6px;
                    color: #1979FF;
                    display: inline-block;
                    border-radius: 2px;
                    border: 1px solid #1979FF;
                }
                &:first-child {
                    margin-top: 12px;
                }
                &:last-child {
                    margin-bottom: 16px;
                }
            }
        }
    }
}
.address-info {
    margin-top: 30px;
    .address-top {
        height: 40px;
        background: #F5F8FA;
        display: flex;
        align-items: center;
        .top-left {
            width: 1%;
            flex: 1;
            .tag {
                line-height: 40px;
                display: inline-block;
                width: 60px;
                color: #fff;
                text-align: center;
                background: #1979FF;
                margin-right: 5px;
            }
            .iconfont {
                color: #7D7D7D;
                margin-left: 8px;
                cursor: pointer;
            }
        }
        .top-right {
            cursor: pointer;
            line-height: 40px;
            padding: 0 18px;
            text-align: center;
            color: #7D7D7D;
            font-size: 20px;
        }
    }
    .address-content {
        display: flex;
        align-items: center;
        border-top: 1px solid #E5E5E5;
        border-bottom: 1px solid #E5E5E5;
        .c-left {
            width: 1%;
            flex: 1;
            padding: 12px;
            border-right: 1px solid #E5E5E5;
            .line {
                display: flex;
                margin-bottom: 20px;
                &:last-child {
                    margin-bottom: 0;
                }
                .name {
                    width: 60px;
                    text-align-last: justify;
                }
                .detail {
                    width: 1%;
                    flex: 1;
                    span {
                        margin-left: 20px;
                    }
                    .iconfont {
                        color: #7D7D7D;
                        margin-left: 10px;
                    }
                }
            }
        }
        .c-right {
            padding: 0 5px;
        }
    }
}
.goods-title {
    cursor: pointer;
}
.no-data {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .no-img {
        max-width: 200px;
    }
    .text {
        color: #999;
        margin-top: 12px;
        padding: 0 20px;
    }
}
