
.right-content {
    flex: 1;
    height: 1%;
    padding: 10px 0 0;
    & > ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
        .el-scrollbar__wrap {

        }
    }
    .score-header {
        line-height: 1;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
    }
    .score-info {
        color: #333;
        font-size: 14px;
        padding: 20px 20px 20px 10px;
        .info-item {
            margin-bottom: 16px;
            &:last-child {
                margin-bottom: 0;
            }
            .time {
                font-size: 18px;
                font-weight: bold;
                line-height: 1;
                .iconfont {
                    font-size: 16px;
                    margin-left: 10px;
                    font-weight: normal;
                }
            }
        }
    }
    .error-box {
        padding: 10px;
        border: 1px solid #eee;
        margin: 0 20px 20px 10px;
        .error {
            display: flex;
            .left {
                color: #666;
            }
            .right {
                width: 1%;
                flex: 1;
                margin-left: 5px;
            }
        }
        .model-answer {
            margin-top: 12px;
            .header {
                color: #666;
                line-height: 1;
            }
            .answer-box {
                .item {
                    display: flex;
                    margin-top: 8px;
                    .left {
                        width: 22px;
                        height: 22px;
                        font-size: 12px;
                        border-radius: 50%;
                        text-align: center;
                        line-height: 22px;
                        &.blue-round {
                            color: #0C6AFF;
                            background: #DEEBFF;
                        }
                        &.yellow-round {
                            color: #FFB000;
                            background: #FFEFCB;
                        }
                    }
                    .right {
                        flex: 1;
                        width: 1%;
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}
.red {
    color: #F61F03;
}
.green {
    color: #00DF69;
}
