
.customerOrder {
    height: 1%;
    flex: 1;
    display: flex;
    flex-direction: column;;
    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
}
.search-box {
    margin: 20px 15px;
    width: calc(100% - 30px);
}
.goods-list {
    height: 1%;
    flex: 1;
    ::v-deep > .el-scrollbar__wrap {
        overflow-x: hidden;
    }
}
.good-item {
    display: flex;
    padding: 10px 15px;
    border-bottom: 1px solid #E5E5E5;
    &:first-child {
        border-top: 1px solid #E5E5E5;
    }
    .good-img {
        width: 80px;
        height: 80px;
        display: flex;
        background: red;
        cursor: pointer;
        img {
            margin: auto;
            max-width: 100%;
            max-height: 100%;
        }
    }
    .good-info {
        width: 1%;
        flex: 1;
        margin-left: 10px;
        font-size: 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .line {
            display: flex;
            .text {
                width: 1%;
                flex: 1;
                span {
                    font-size: 14px;
                }
            }
        }
    }
}
.icon-gray {
    color: #7D7D7D;
    cursor: pointer;
}
.red {
    color: #F61F03;
}
.golden {
    color: #B67F00;
}
.text-overflow-2 {
    cursor: pointer;
}
