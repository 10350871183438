
.tox {
    &.tox-tinymce-aux {
        .tox-toolbar__overflow {
            left: 370px!important;
            right: 640px!important;
            top: -257px!important;
            border: none!important;
            box-shadow: none!important;
            border-radius: 0!important;
            background: #fff;
        }
        .tox-tbtn {
            &:hover {
                background: #f6faff!important;
            }
            &:focus {
                background: #fff!important;
            }
            &.tox-tbtn--enabled {
                background: #f6faff!important;
            }
        }
        .tox-tbtn--bespoke .tox-tbtn__select-label {
            width: 50px!important;
        }
    }
}
